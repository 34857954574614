/**
 * Created by Andrey Popov on 12.03.24
 */

var ScrollableIconTableView = cc.Node.extend({
    ctor: function (model) {
        this._super();

        this.model = model;
        this.scrollableIconTable = Merge.currentMerge.scrollableIconTable;

        var styles = cleverapps.styles.ScrollableIconTableView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.size);

        if (bundles.questsgroupicon.frames.quests_scroll_bg) {
            var bg = cleverapps.UI.createScale9Sprite(bundles.questsgroupicon.frames.quests_scroll_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize2(this.getContentSize());
            bg.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(bg);
        }

        this.icons = this.scrollableIconTable.active.map(function (icon) {
            if (!icon) {
                return this.createFakeIcon();
            }

            var iconView = this.createItemView(icon);
            this.makeIconInteractive(iconView);
            return iconView;
        }.bind(this));

        var margin = cleverapps.styles.ScrollableIconTableView.margin[cleverapps.resolution.mode];

        var iconsLayout = this.iconsLayout = new cleverapps.Layout(this.icons, {
            direction: cleverapps.UI.VERTICAL,
            margin: margin,
            padding: styles.padding
        });

        var scrollView = this.scrollView = new cleverapps.UI.ScrollView(iconsLayout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false,
            mouseScroll: false,
            snapHandler: this.snapHandler.bind(this),
            clipping: styles.clipping
        });

        this.iconSlotWidth = this.icons[0].width + margin;
        this.iconSlotHeight = this.icons[0].height + margin;

        this.updateScrollTouchState();

        this.scrollView.setContentSize(this.width, this.height);
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.setPositionRound(this.width / 2, this.height / 2);
        scrollView.scrollToDefault();

        this.addChild(scrollView);

        var aim = new cc.Node();
        aim.setAnchorPoint(0.5, 0.5);
        aim.setPositionRound({ x: { align: "center" }, y: { align: "top", dy: -styles.padding.y - this.icons[0].height / 2 } });
        this.addChild(aim);
        cleverapps.aims.registerTarget(["quests"], aim, { controls: this.model.control });

        this.model.onGetIconViewListener = this.createListener(this.getIconView.bind(this));

        this.scrollableIconTable.on("add", this.add.bind(this), this);
        this.scrollableIconTable.on("remove", this.remove.bind(this), this);
        this.scrollableIconTable.on("move", this.move.bind(this), this);
        this.scrollableIconTable.on("resize", this.resize.bind(this), this);
        this.scrollableIconTable.on("scrollTo", this.scrollTo.bind(this), this);

        this.updateSize();
    },

    updateSize: function () {},

    updateScroll: function () {
        this.iconsLayout.setItems(this.icons, false);
        this.icons.forEach(this.makeIconInteractive.bind(this));
        this.scrollView.updateInnerContent();
        this.scrollView.scrollToDefault();
        this.updateScrollTouchState();
    },

    add: function (options, callback) {
        callback = callback || function () {};

        if (options.fake) {
            var fakeIcon = this.createFakeIcon();
            this.icons.push(fakeIcon);
            this.iconsLayout.addChild(fakeIcon);
            callback();
            return;
        }

        var replaceIndex = options.replaceIndex;
        var iconView = this.createItemView(this.scrollableIconTable.active[replaceIndex !== undefined ? replaceIndex : options.insertIndex]);

        if (replaceIndex !== undefined) {
            this.icons[replaceIndex].removeFromParent();
            this.icons[replaceIndex] = iconView;
        } else {
            this.icons.splice(options.insertIndex, 0, iconView);
        }

        this.updateScroll();

        var action;
        var finalPosition = iconView.getPosition();
        if (replaceIndex !== undefined) {
            action = new cc.Sequence(
                new cc.ScaleTo(0.2, 0.8).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut())
            );
        } else {
            action = this.addIconAnimation(iconView);
        }
        iconView.runAction(action).setFinalize(function () {
            iconView.setScale(1);
            iconView.setPosition(finalPosition);
            iconView.onAddToTable && iconView.onAddToTable();
            callback();
        });
    },

    addIconAnimation: function (iconView) {
        var styles = cleverapps.styles.ScrollableIconTableView;

        this.iconsLayout.y += this.iconSlotHeight;
        this.iconsLayout.runAction(new cc.MoveBy(0.5, 0, -this.iconSlotHeight).easing(cc.easeBackOut()));

        var targetPosition = iconView.getPosition();
        iconView.setScale(0.05);
        iconView.setPosition(targetPosition.x + styles.animation.offset.x, targetPosition.y + styles.animation.offset.x);
        iconView.visible = false;
        var bezierPoints = [iconView.getPosition(),
            cc.p(
                targetPosition.x + styles.animation.bezier.x,
                targetPosition.y + styles.animation.bezier.y
            ), targetPosition];
        return new cc.Sequence(
            new cc.DelayTime(0.05),
            new cc.Show(),
            new cc.Spawn(
                new cc.ScaleTo(0.2, 1),
                new cc.BezierTo(0.22, bezierPoints).easing(cc.easeOut(2))
            )
        );
    },

    remove: function (index, silent, callback) {
        var iconView = this.icons[index];

        var afterRemove = function () {
            iconView.removeFromParent();
            this.icons.splice(this.icons.indexOf(iconView), 1);
            callback && callback();
        }.bind(this);

        if (silent) {
            afterRemove();
        } else {
            iconView.runAction(this.iconRemoveAnimation(iconView)).setFinalize(afterRemove);
        }
    },

    iconRemoveAnimation: function () {
        return new cc.Spawn(
            new cc.ScaleTo(0.3, 0).easing(cc.easeOut(3)),
            new cc.MoveBy(0.5, -this.width / 2, 0).easing(cc.easeOut(3))
        );
    },

    move: function (index, direction) {
        var delta = this.calculateDelta(direction, index);
        var iconView = this.icons[index];
        iconView.stopAllActions();

        var finalPosition = cc.pAdd(iconView.getPosition(), delta);
        iconView.runAction(this.iconMoveAnimation(delta)).setFinalize(function () {
            iconView.setPosition(finalPosition);
        });
    },

    calculateDelta: function (direction) {
        var containerPositionY = this.scrollView.getContainerPosition().y;
        var deltaUp = Math.min(this.iconSlotHeight, containerPositionY - this.scrollView.min.y);
        var deltaDown = Math.min(this.iconSlotHeight - deltaUp, this.scrollView.max.y - containerPositionY);

        return cc.p(0, direction === "up" ? deltaUp : -deltaDown);
    },

    iconMoveAnimation: function (delta) {
        return new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.MoveBy(0.2, delta).easing(cc.easeBackInOut())
        );
    },

    resize: function (callback) {
        this.iconsLayout.reshape();
        this.scrollView.updateInnerContent();
        callback();
    },

    scrollTo: function (index, silent, callback) {
        this.scrollToItem(this.icons[index], function () {
            this.updateScrollTouchState();
            callback && callback();
        }.bind(this), silent);
    },

    createItemView: function (item) {
        return new QuestIcon(item, { images: QuestIcon.Images.questsgroup });
    },

    getIconView: function (iconToFind) {
        return this.icons.find(function (icon) {
            return icon.quest === iconToFind;
        });
    },

    scrollToItem: function (iconView, callback, silent) {
        callback = callback || function () {};

        if (!iconView || this.icons.indexOf(iconView) === 0) {
            iconView = this.icons[1];
        }

        var iconIndex = this.icons.indexOf(iconView);
        if (iconIndex > 1 && this.icons.length === 4 && this.icons[3].fake) {
            iconView = this.icons[iconIndex - 1];
        }

        var styles = cleverapps.styles.ScrollableIconTableView;
        var margin = styles.margin[cleverapps.resolution.mode];

        var p = cc.p(0, Math.max(iconView.y - this.scrollView.innerContent.height / 2 - margin, this.scrollView.min.y));

        if (Math.abs(this.scrollView.getContainerPosition().y - p.y) <= styles.scrollSensitivity) {
            callback();
        } else if (silent) {
            this.scrollView.scrollToPoint(p);
            callback();
        } else {
            this.scrollView.runAction(
                new cc.Sequence(
                    new cc.ScrollAction(p, {
                        duration: 0.3,
                        targetPoint: true
                    }).easing(cc.easeBackInOut()),
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(callback)
                )
            );
        }
    },

    createFakeIcon: function () {
        var fakeIcon = new cc.Sprite(bundles.questsgroupicon.frames.quest_icon_bg);
        fakeIcon.setAnchorPoint(0.5, 0.5);
        fakeIcon.fake = true;
        fakeIcon.setVisible(false);
        return fakeIcon;
    },

    snapHandler: function (position, isMoveUp) {
        var styles = cleverapps.styles.ScrollableIconTableView;
        var h = this.iconSlotHeight;

        var wanted = Math.ceil(position.y / h - 1) * h + h - 2 * styles.padding.y;
        if (this.icons.length % 2 === 0) {
            wanted -= h / 2;
        }

        if (isMoveUp) {
            return cc.p(0, wanted > position.y ? wanted - position.y : wanted + h - position.y);
        }
        return cc.p(0, wanted < position.y ? wanted - position.y : wanted - h - position.y);
    },

    updateScrollTouchState: function () {
        var hasFakeIcons = this.icons.filter(function (icon) {
            return icon.fake;
        }).length > 0;

        this.scrollView.touchScrollDisabled = hasFakeIcons;
    },

    makeIconInteractive: function (icon) {
        if (icon.fake || typeof CustomerRecipeIconView !== "undefined" && icon instanceof CustomerRecipeIconView) {
            return;
        }

        icon.update();
        icon.quest.on("updateProgress", icon.updateProgress.bind(icon), icon);

        var clickHandler = function () {
            cleverapps.audio.playSound(bundles.main.urls.click_effect);

            if (this.scrollView.isVisiblePoint(icon)) {
                this.model.onPressed(icon);
            } else {
                this.scrollToItem(icon, function () {
                    this.model.onPressed(icon);
                }.bind(this));
            }
        }.bind(this);

        var dragHandler = cleverapps.UI.onDrag(icon, {
            onClick: clickHandler,
            onDragStart: function () {
                var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
                if (questsGroupIcon) {
                    questsGroupIcon.hideInfo();
                }
            }
        });
        dragHandler.interactiveScale = true;

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(icon.progress, clickHandler, {
                onDoubleClick: function () {
                    icon.quest.addProgress(1);
                },
                interactiveScale: false
            });
        }
    },

    onShow: function () {
        this.model.allowInfoView();
    },

    onHide: function () {
        this.model.denyInfoView();
    }
});

cleverapps.styles.ScrollableIconTableView = {
    clipping: true,

    size: [
        {
            width: 210,
            height: 720
        },
        {
            width: 210,
            height: 720
        },
        {
            width: 210,
            height: 720
        }
    ],

    margin: [
        30,
        30,
        30
    ],
    scrollSensitivity: 6,

    animation: {
        offset: {
            x: -60,
            y: -40
        },

        bezier: {
            x: 100,
            y: 100
        }
    },

    padding: {
        x: 0,
        y: 20
    }
};
