/**
 * Created by vladislav on 6/5/19
 */

var ShopWindow = CleverappsWindow.extend({
    ctor: function (options) {
        this.options = options || {};
        this.tab = this.options.tab;

        if (this.tab === ShopWindow.TABS.HARD_CURRENCY && cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_DISABLED) {
            return false;
        }

        if (options.force && options.force.productOptions) {
            var productKey = options.force.productKey;
            this.originalProduct = cleverapps.clone(VirtualProducts[productKey]);
            VirtualProducts[productKey] = Object.assign(VirtualProducts[productKey], options.force.productOptions);
        }

        this.onFinishShowUp = function () {};
        this._super.apply(this, arguments);
    },

    needGoldInfo: function () {
        return ShopUserGoldView.IsAvailable() && !this.isVertical() && this.tab !== ShopWindow.TABS.LIVES;
    },

    updateGoldInfo: function () {
        if (this.needGoldInfo()) {
            this.goldInfo = this.goldInfo || new ShopUserGoldView();
            this.goldInfo.showUp();
        } else if (this.goldInfo) {
            this.goldInfo.hide(undefined, true);
        }
    },

    onWindowLoaded: function () {
        var styles = cleverapps.styles.ShopWindow;

        this.windowTitle = new WindowTitle(this, this.getTitle());
        this.windowTitle.setPositionRound(styles.title.position.x, styles.title.position.y);
        this.windowTitle.setVisible(false);
        this.addChild(this.windowTitle);

        if (this.needGoldInfo()) {
            this.goldInfo = new ShopUserGoldView();
        }

        Lottery.addText(this);

        this._super({
            name: "shopwindow_tab" + this.tab,
            content: this.createContent(this.tab),
            title: false,
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            closeButtonConstructor: this.isVertical() ? CloseRoundButton : undefined,
            styles: styles,
            customTitle: true
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.SHOP_OPEN + this.tab, {
            store_type: this.getTitle()
        });

        this.tiles.forEach(function (tile) {
            if (tile.onReady) {
                tile.onReady();
            }
        });

        if (this.tab === ShopWindow.TABS.HARD_CURRENCY) {
            if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_ADS) {
                var dialogue;
                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.2),
                    new cc.CallFunc(function () {
                        dialogue = this.dialogue = new MinimalDialogue({
                            text: "VideoShopMinimal",
                            rects: this.getMinimalDialogueRects()
                        });
                        this.addChild(dialogue);
                        dialogue.display();
                    }.bind(this)),
                    new cc.DelayTime(3),
                    new cc.CallFunc(function () {
                        dialogue.remove();
                    })
                ));
            }
        }
    },

    showTileForce: function () {
        var tile = this.tiles.find(function (tile) {
            return tile.tileModel.product.itemId === this.options.force.productKey;
        }, this);

        if (tile) {
            cleverapps.forces.create(tile.button, { pointer: true }, { actives: [tile] });
        }
    },

    getTitle: function () {
        var text = "ShopWindow.title";

        if (cleverapps.config.type === "merge") {
            switch (this.tab) {
                case ShopWindow.TABS.HARD_CURRENCY: text = "ShopWindow.title.ruby"; break;
                case ShopWindow.TABS.LIVES: text = "ShopWindow.title.energy"; break;
                case ShopWindow.TABS.SOFT_CURRENCY: text = "ShopWindow.title.coins"; break;
                case ShopWindow.TABS.UNITS_FRUIT: text = "ShopWindow.title.fruit"; break;
                case ShopWindow.TABS.UNITS_RESOURCE: text = "ShopWindow.title.resource"; break;
                case ShopWindow.TABS.UNITS_DISCOUNT: text = "ShopWindow.title.discount"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN: text = "ShopWindow.title.expedition.halloween"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY: text = "ShopWindow.title.expedition.blackfriday"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_XMAS: text = "ShopWindow.title.expedition.xmas"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA: text = "ShopWindow.title.expedition.dragonia"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2: text = "ShopWindow.title.expedition.dragonia"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3: text = "ShopWindow.title.expedition.dragonia"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA: text = "ShopWindow.title.expedition.undersea"; break;
                case ShopWindow.TABS.UNITS_ADS: text = "ShopWindow.title.ads"; break;
                case ShopWindow.TABS.UNITS_ADS_VIP: text = "ShopWindow.title.ads_vip"; break;
                case ShopWindow.TABS.UNITS_ADS_UNDECIDED: text = "ShopWindow.title.ads"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL: text = "ShopWindow.title.expedition.rapunzel"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_EASTER: text = "ShopWindow.title.expedition.easter"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_COLLECTIONS: text = "ShopWindow.title.expedition.collections"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_CHINA: text = "ShopWindow.title.expedition.china"; break;
                case ShopWindow.TABS.UNITS_EXPEDITION_SPRING: text = "ShopWindow.title.expedition.spring"; break;
            }
        }

        return text;
    },

    onHide: function () {
        this._super();

        if (this.goldInfo) {
            this.goldInfo.hide(undefined, true);
        }
        this.hidden = true;
    },

    buyProduct: function (tileModel, buyParams) {
        if (this.hidden) {
            return;
        }

        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        tileModel.buy(this.close.bind(this, true), buyParams);

        if (this.originalProduct) {
            VirtualProducts[this.options.force.productKey] = this.originalProduct;
        }
    },

    isVertical: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
    },

    getOverlappingNodes: function () {
        var nodes = this._super();
        return nodes.concat(this.windowTitle);
    },

    inflateWindow: function () {
        this._super();

        var styles = cleverapps.styles.ShopWindow;

        if (this.windowTitle) {
            this.windowTitle.setPositionRound(styles.title.position);
        }

        if (cleverapps.oneOf(this.closeButton, CloseRoundButton)) {
            this.closeButton.adjustPosition(this.windowTitle);

            cleverapps.UI.fitToBox(this.windowTitle, {
                width: 2 * (this.closeButton.x - this.closeButton.width / 2 - this.width / 2),
                maxScale: this.isVertical() ? 0.8 : 1
            });
        }

        var bgSize = cleverapps.resolution.getBgSize();
        var safePadding = cleverapps.resolution.getSafePadding();

        var available = cleverapps.UI.calcBoundaries(this.getOverlappingNodes());
        available = cc.rectSubPadding(available, this.getBackgroundPadding());

        var availablePadding = cc.padding(
            bgSize.height - available.y - available.height,
            bgSize.width - available.x - available.width,
            available.y,
            available.x
        );

        if (this.isVertical()) {
            this.shopLayout.setScale(Math.min(1, available.width / this.shopLayout.width));
        } else {
            this.shopLayout.setScale(Math.min(1, available.height / this.shopLayout.height));

            if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL && connector.info.isMobile) {
                this.shopLayout.setScale(this.shopLayout.scale * styles.horizontalMobile.scale);
            }
        }

        if (this.isVertical()) {
            this.contentWrap.setContentSize(bgSize.width, this.shopLayout.height * this.shopLayout.scaleY + availablePadding.bottom);
            this.scroll.setContentSize2(bgSize.width, bgSize.height - availablePadding.top);
        } else {
            this.contentWrap.setContentSize(this.shopLayout.width * this.shopLayout.scaleX + availablePadding.left + availablePadding.right, bgSize.height);
            this.scroll.setContentSize2(bgSize);
        }

        this.scroll.setPositionRound(bgSize.width / 2 - safePadding.left, this.scroll.height / 2 - safePadding.bottom);
        this.scroll.updateInnerContent();

        var position = this.contentWrap.convertToNodeSpace(cc.p(available.x + available.width / 2, available.y + available.height / 2));

        if (this.isVertical()) {
            this.shopLayout.setPositionRound(position.x, this.shopLayout.height * this.shopLayout.scaleY / 2 + availablePadding.bottom);
        } else {
            this.shopLayout.setPositionRound(this.shopLayout.width * this.shopLayout.scaleX / 2 + availablePadding.left, position.y);
        }

        position = this.convertToNodeSpace(cc.p(available.x + available.width / 2, available.y + available.height / 2));

        if (this.isVertical()) {
            this.shopLayoutPlaceholder.setContentSize(this.shopLayout.width * this.shopLayout.scaleX, this.height);
            this.shopLayoutPlaceholder.setPositionRound(position.x, this.height / 2);
        } else {
            this.shopLayoutPlaceholder.setContentSize(this.width, this.shopLayout.height * this.shopLayout.scaleY);
            this.shopLayoutPlaceholder.setPositionRound(this.width / 2, position.y);
        }

        if (!this.scrolledToTile) {
            this.scrollToTile();
            this.scrolledToTile = true;
        }
    },

    scrollToTile: function () {
        this.scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.LOWER_RIGHT);

        var tile = this.selectTileForScroll();

        var target = tile || cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT;
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.CallFunc(function () {
                this.scroll.scrollTo(target, 0.8, {
                    easing: cc.easeBackInOut(),
                    callback: this.onFinishShowUp
                });

                if (this.options.finger && tile) {
                    this.runAction(new cc.Sequence(
                        new cc.DelayTime(0.5),
                        new cc.CallFunc(function () {
                            tile.addFinger();
                        })
                    ));
                }
            }, this),
            new cc.CallFunc(function () {
                if (this.options.force) {
                    this.showTileForce();
                }
            }, this)
        ));
    },

    selectTileForScroll: function () {
        var selectedTile;
        if (this.options.priorityType) {
            selectedTile = this.tiles.find(function (tile) {
                return tile instanceof this.options.priorityType;
            }.bind(this));
        }

        if (this.options.attention) {
            selectedTile = this.tiles.find(function (tile) {
                return this.options.attention.includes(tile.tileModel.getItemId());
            }.bind(this));
        }

        selectedTile = selectedTile || this.tiles.find(function (tile) {
            return tile.tileModel.isAttention();
        });

        selectedTile = selectedTile || this.tiles.find(function (tile) {
            return tile.tileModel.wantsScroll();
        });

        return selectedTile;
    },

    setupChildren: function () {
        this._super();

        if (this.scroll.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL && this.isVertical()
            || this.scroll.dir === cleverapps.UI.ScrollView.DIR_VERTICAL && !this.isVertical()) {
            this.scroll.removeFromParent(true);
            this.createContent(this.tab);
        }

        this.updateGoldInfo();
    },

    createTilesLayout: function () {
        var styles = cleverapps.styles.ShopWindow;

        var rowLength = this.isVertical() ? 2 : this.tiles.length;

        var padding = styles.padding;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            padding = styles.vertical.padding;
        } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL && connector.info.isMobile) {
            padding = styles.horizontalMobile.padding;
        }

        this.shopLayout = new cleverapps.WrapGridLayout(this.tiles, {
            columns: rowLength,
            margin: styles.margin,
            padding: padding,
            ascZOrder: true
        });

        this.shopLayoutPlaceholder = new cc.Node();
        this.shopLayoutPlaceholder.avoidNode = "Window";
        this.shopLayoutPlaceholder.setAnchorPoint(0.5, 0.5);
        this.addChild(this.shopLayoutPlaceholder);
    },

    createTiles: function (tab) {
        var tileModels = ShopProductSource.listTileModels(tab);

        this.tiles = tileModels.map(function (tileModel) {
            var ViewClassName = tileModel.getViewClassName();
            if (tileModel instanceof SubscriptionTileModel) {
                tileModel.onClaim = this.close.bind(this);
            }
            return new ViewClassName(tileModel, {
                onClicked: this.buyProduct.bind(this),
                cart: this.cart
            });
        }, this);
    },

    createContent: function (tab) {
        this.createTiles(tab);
        this.createTilesLayout();

        this.contentWrap = new cc.Node();
        this.contentWrap.setAnchorPoint(0.5, 0.5);
        this.contentWrap.addChild(this.shopLayout);
        this.contentWrap.visibilityCheckList = this.tiles;

        this.scroll = new cleverapps.UI.ScrollView(this.contentWrap, {
            childrenVisibilityPadding: cleverapps.styles.ShopWindow.tilesVisibilityPadding,
            direction: this.isVertical() ? cleverapps.UI.ScrollView.DIR_VERTICAL : cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            clipping: this.isVertical(),
            scrollBarEnabled: false
        });
        this.scroll.setLocalZOrder(1);
        this.addChild(this.scroll);

        return new cc.Node();
    },

    onShow: function () {
        this.updateGoldInfo();

        this.hidden = undefined;
        this.tilesLayoutShowAnimation();

        this.windowTitle.show();

        this._super();
    },

    getEventNodes: function () {
        return this.options.force ? [] : [this];
    },

    onClose: function () {
        if (this.tab === ShopWindow.TABS.LIVES && cleverapps.config.type === "merge"
            && !cleverapps.lives.canTake(cleverapps.lives.getMaxLives() * 0.2) && Merge.currentMerge.specialEnergyOffer.isReady()
            && !(cleverapps.windows.currentWindow() instanceof RewardWindow)) {
            Merge.currentMerge.specialEnergyOffer.wantsToShow();
        }

        if (this.options.onClose) {
            this.options.onClose();
        }
    },

    beforeCloseAnimation: function (callback) {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        var buyed = cleverapps.windows.currentWindow() instanceof RewardWindow
            || typeof UnitsShopTab !== "undefined" && UnitsShop.cartRewards && UnitsShop.cartRewards.length > 0;

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.SHOP_CLOSE, {
            store_type: this.getTitle(),
            stay_time: (Date.now() - this.openTime) <= 30000 ? "sec0_30" : "sec30_more",
            stay_whether_to_buy: buyed ? 1 : 0
        });

        callback = cleverapps.wait(2, callback);

        if (this.goldInfo) {
            this.goldInfo.remove(callback);
        } else {
            callback();
        }

        if (cleverapps.oneOf(this.closeButton, BandButton, CloseRoundButton)) {
            this.closeButton.hide();
        }

        if (this.windowTitle) {
            this.windowTitle.hide();
        }

        cleverapps.audio.playSound(bundles.main.urls.close_window_effect || bundles.main.urls.window_effect);
        this.tilesLayoutCloseAnimation(callback);
    },

    tilesLayoutShowAnimation: function () {
        var step = 0.075;
        var maxDelay = step * Math.ceil((this.tiles.length - 1) * 0.5) + 0.5;
        for (var index = this.tiles.length - 1; index >= 0; index--) {
            var delay = step * Math.ceil(index * 0.5);
            var badge = this.tiles[index].tileBadge;
            if (badge) {
                badge.setVisible(false);
                badge.runAction(new cc.Sequence(
                    new cc.ScaleTo(0, 0),
                    new cc.DelayTime(maxDelay),
                    new cc.PlaySound(bundles.tile_shop.urls.badge_appear_effect),
                    new cc.Show(),
                    new cc.ScaleTo(0.4, badge.scale).easing(cc.easeBackOut())
                ));
            }
            var adsBubble = this.tiles[index].adsBubble;
            if (adsBubble) {
                adsBubble.setActive(false);
                adsBubble.runAction(new cc.Sequence(
                    new cc.DelayTime(maxDelay),
                    new cc.CallFunc(adsBubble.setActive.bind(adsBubble, true))
                ));
            }
            this.tiles[index].setVisible(false);
            this.tiles[index].runAction(new cc.Sequence(
                new cc.ScaleTo(0, 0),
                new cc.CallFunc(function () {
                    if (!this.beyondScroll) {
                        this.setVisible(true);
                    }
                }.bind(this.tiles[index])),
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.4, this.tiles[index].scale).easing(cc.easeBackOut())
            ));
        }
    },

    tilesLayoutCloseAnimation: function (closeCallback) {
        var step = 0.075;
        var close = cleverapps.wait(this.tiles.length, closeCallback);
        for (var index = this.tiles.length - 1; index >= 0; index--) {
            this.tiles[index].runAction(new cc.Sequence(
                new cc.DelayTime(step * Math.ceil(index * 0.5)),
                new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                new cc.CallFunc(close.bind(this, index))
            ));
        }
    },

    closeAnimation: function (callback) {
        callback();
    }
});

var LivesShopWindow = ShopWindow.extend({
    ctor: function (options) {
        options = options || {};

        this._super({
            tab: ShopWindow.TABS.LIVES,
            onClose: options.onClose,
            force: options.force
        });
    }
});

var SoftCurrencyShopWindow = ShopWindow.extend({
    ctor: function (options) {
        options = options || {};

        this._super({
            tab: ShopWindow.TABS.SOFT_CURRENCY,
            force: options.force
        });
    }
});

var HardCurrencyShopWindow = ShopWindow.extend({
    ctor: function (options) {
        options = options || {};

        this._super({
            tab: ShopWindow.TABS.HARD_CURRENCY,
            attention: options.attention
        });
    }
});

ShopWindow.prototype.listBundles = function () {
    return ["tile_shop"];
};

ShopWindow.TABS = {
    HARD_CURRENCY: 0,
    LIVES: 1,
    SOFT_CURRENCY: 2,
    UNITS_FRUIT: 3,
    UNITS_RESOURCE: 4,
    UNITS_DISCOUNT: 5,
    UNITS_EXPEDITION_HALLOWEEN: 6,
    UNITS_EXPEDITION_XMAS: 8,
    UNITS_EXPEDITION_DRAGONIA: 10,
    UNITS_EXPEDITION_UNDERSEA: 12,
    UNITS_ADS: 14,
    UNITS_ADS_VIP: 15,
    UNITS_ADS_UNDECIDED: 19,
    FOGS_VALUABLES: 20,
    UNITS_EXPEDITION_DRAGONIA2: 21,
    UNITS_EXPEDITION_RAPUNZEL: 22,
    UNITS_EXPEDITION_EASTER: 23,
    UNITS_EXPEDITION_COLLECTIONS: 24,
    UNITS_EXPEDITION_DRAGONIA3: 25,
    UNITS_EXPEDITION_CHINA: 26,
    UNITS_EXPEDITION_BLACKFRIDAY: 27,
    UNITS_EXPEDITION_SPRING: 28
};

cleverapps.styles.ShopWindow = {
    title: {
        hideAnimation: {
            moveDownY: 30
        },
        position: [{
            x: { align: "center" },
            y: { align: "top", dy: -126 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: -185 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: -185 }
        }]
    },
    margin: { x: 30, y: 30 },
    padding: { x: 10, y: 10 },
    tilesVisibilityPadding: 30,

    horizontalMobile: {
        scale: 1.25,
        padding: { x: 0, top: 140, bottom: 90 }
    },

    vertical: {
        padding: { x: 10, top: 40, bottom: 10 }
    },

    mouseScrollSpeed: 400
};

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TILE_SHOP_BADGE_STROKE: {
        color: new cc.Color(100, 34, 22, 255),
        size: 2
    },

    TILE_SHOP_SHADOW: {
        color: new cc.Color(100, 34, 22, 200),
        direction: cc.size(0, -3),
        blur: 1
    },

    TILE_SHOP_STROKE: {
        color: new cc.Color(100, 34, 22, 255),
        size: 3
    }
});
