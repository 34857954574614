/**
 * Created by vladislav on 13.11.2020
 */

var FlyingAd = function () {
    cleverapps.EventEmitter.call(this);

    this.nextTime = 0;

    cleverapps.focusManager.registerControl("flyingAd", function (visible) {
        if (this.enabled) {
            this.trigger("toggle", visible);
        }
    }.bind(this));
};

FlyingAd.prototype = Object.create(cleverapps.EventEmitter.prototype);
FlyingAd.prototype.constructor = FlyingAd;

FlyingAd.prototype.setGame = function () {
    cleverapps.userStatus.on("inactive_very_short", this.update.bind(this));
};

FlyingAd.prototype.reset = function () {
    this.disable();

    this.purge();

    this.type = undefined;

    if (cleverapps.environment.isGameScene() && cleverapps.config.type === "board") {
        this.setDelay(cleverapps.parseInterval(FlyingAd.GAMESCENE_DELAY));
    }
};

FlyingAd.prototype.getSkin = function () {
    return FlyingAd.DATA[this.type].skin;
};

FlyingAd.prototype.needUpdate = function () {
    return !this.enabled && this.isReady();
};

FlyingAd.prototype.setDelay = function (delay) {
    this.nextTime = Date.now() + delay;
};

FlyingAd.prototype.isReady = function () {
    return Date.now() >= this.nextTime;
};

FlyingAd.prototype.update = function () {
    if (this.enabled) {
        return;
    }

    if (!this.isReady()) {
        return;
    }

    var type = this.selectType();
    if (type !== undefined) {
        this.setType(type);

        this.enable();
    } else {
        this.disable();
    }
};

FlyingAd.prototype.isClickAvailable = function () {
    if (!this.enabled) {
        return false;
    }

    if (Game.currentGame && Game.currentGame.counter.isActive()) {
        return false;
    }

    return true;
};

FlyingAd.prototype.onClick = function () {
    if (this.isClickAvailable()) {
        this.playAds();
    }
};

FlyingAd.prototype.selectType = function () {
    if (cleverapps.config.debugMode && FlyingAd.DEBUG_TYPE !== undefined) {
        var debugType = FlyingAd.DEBUG_TYPE;
        delete FlyingAd.DEBUG_TYPE;

        return debugType;
    }

    var availableTypes = Object.values(FlyingAd.TYPES).filter(function (type) {
        return FlyingAd.DATA[type] && cleverapps.adsLimits.state(FlyingAd.DATA[type].limit) === AdsLimits.STATE_READY && FlyingAd.DATA[type].filter();
    });

    return cleverapps.Random.mathChoose(availableTypes);
};

FlyingAd.prototype.setType = function (type) {
    if (this.type === type) {
        return;
    }

    this.type = type;

    this.trigger("changeType");
};

FlyingAd.prototype.playAds = function () {
    var data = FlyingAd.DATA[this.type];
    var success = false;
    cleverapps.focusManager.display({
        focus: "FlyingAdReward",
        actions: [
            function (f) {
                cleverapps.rewardedAdsManager.playRewarded(data.limit, function () {
                    cleverapps.adsLimits.watch(data.limit);
                    success = true;
                    f();
                }, function () {
                    f();
                });

                this.clearTimeout();
                this.setDelay(cleverapps.parseInterval(FlyingAd.COOLDOWN));
                this.enabled = false;
                this.trigger("disappear");
            }.bind(this),

            function (f) {
                if (success) {
                    new RewardWindow(data.reward, {
                        event: cleverapps.EVENTS.EARN.FLYING,
                        target: data.target
                    });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                } else {
                    f();
                }
            },

            function (f) {
                if (success && data.action) {
                    data.action();
                }
                f();
            }
        ]
    });
};

FlyingAd.prototype.enable = function () {
    if (this.enabled) {
        return;
    }

    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    this.enabled = true;

    if (cleverapps.environment.isGameScene()) {
        this.timeout = cleverapps.timeouts.setTimeout(function () {
            this.setDelay(cleverapps.parseInterval(FlyingAd.DIDNT_CLICK_COOLDOWN));

            this.clearTimeout();

            this.enabled = false;

            this.trigger("disappear");
        }.bind(this), cleverapps.parseInterval(FlyingAd.DURATION));
    }

    this.trigger("enable");
};

FlyingAd.prototype.disable = function () {
    if (!this.enabled) {
        return;
    }

    this.clearTimeout();

    this.enabled = false;

    this.trigger("disable");
};

FlyingAd.prototype.clearTimeout = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
    }
};

FlyingAd.COOLDOWN = "15 seconds";
FlyingAd.GAMESCENE_DELAY = "1 minute";
FlyingAd.DURATION = "45 seconds";
FlyingAd.DIDNT_CLICK_COOLDOWN = "1 minutes";

if (cleverapps.config.debugMode) {
    FlyingAd.DIDNT_CLICK_COOLDOWN = "30 seconds";
}

FlyingAd.TYPES = {
    TYPE_HINT: 0,
    TYPE_STAR: 1,
    TYPE_COMBO: 3,
    TYPE_DISCOVER: 4,
    TYPE_CARDS: 5,
    TYPE_HARD_SOFT: 7,
    TYPE_MOVES: 8,
    TYPE_SHUFFLE: 9
};

FlyingAd.DATA = {};
FlyingAd.DATA[FlyingAd.TYPES.TYPE_HINT] = {
    limit: AdsLimits.TYPES.FLYING_HINT,
    skin: "hint",
    filter: function () {
        return cleverapps.environment.isGameScene() && cleverapps.config.type === "board";
    },
    reward: {
        boosters: {
            3: 1
        }
    }
};
FlyingAd.DATA[FlyingAd.TYPES.TYPE_STAR] = {
    limit: AdsLimits.TYPES.FLYING_STAR,
    skin: "star",
    filter: function () {
        return cleverapps.meta.getLocationType() === "simple" && cleverapps.environment.isMainScene();
    },
    reward: {
        locationProgress: 1
    }
};
FlyingAd.DATA[FlyingAd.TYPES.TYPE_COMBO] = {
    limit: AdsLimits.TYPES.FLYING_BOOSTER,
    skin: "heroes",
    filter: function () {
        return cleverapps.environment.isMainScene() && cleverapps.config.type === "match3";
    },
    reward: {
        boosters: {
            5: 1
        }
    }
};
FlyingAd.DATA[FlyingAd.TYPES.TYPE_DISCOVER] = {
    limit: AdsLimits.TYPES.FLYING_DISCOVER,
    skin: "hint",
    filter: function () {
        var game = Game.currentGame;

        if (!cleverapps.environment.isGameScene() || !game || !game.discoverBooster) {
            return false;
        }
        if (game.inflamer.hasMistakes()) {
            return true;
        }
        return game.level.episodeNo >= 1 || game.level.levelNo >= 3;
    },
    reward: {
        boosters: {
            9: 1
        }
    }
};
FlyingAd.DATA[FlyingAd.TYPES.TYPE_CARDS] = {
    limit: AdsLimits.TYPES.FLYING_MOVES,
    skin: "cards",
    filter: function () {
        return cleverapps.environment.isGameScene() && cleverapps.config.type === "solitaire";
    },
    reward: {
        cards: 2
    }
};

FlyingAd.DATA[FlyingAd.TYPES.TYPE_MOVES] = {
    limit: AdsLimits.TYPES.FLYING_MOVES,
    skin: "moves",
    filter: function () {
        return cleverapps.environment.isMainScene() && cleverapps.config.type === "match3";
    },
    reward: {
        boosters: {
            6: 1
        }
    }
};

FlyingAd.DATA[FlyingAd.TYPES.TYPE_HARD_SOFT] = {
    limit: AdsLimits.TYPES.FLYING_HARD_SOFT,
    skin: "coin",
    filter: function () {
        return cleverapps.environment.isMainScene();
    },
    reward: {
        hard: cleverapps.config.soft ? undefined : 10,
        soft: cleverapps.config.soft ? 10 : undefined
    }
};

FlyingAd.DATA[FlyingAd.TYPES.TYPE_SHUFFLE] = {
    limit: AdsLimits.TYPES.BOOSTER_ADS,
    skin: "replaceall",
    filter: function () {
        if (!cleverapps.flags.lowMonetization || !cleverapps.environment.isGameScene()) {
            return false;
        }

        var booster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_SHUFFLE);
        return booster && booster.isAvailable();
    },
    reward: {
        boosters: {
            16: 1
        }
    }
};

cleverapps.InitByFeature["flyingad"] = function () {
    cleverapps.flyingAd = new FlyingAd();
};