/**
 * Created by vladislav on 13.01.2022
 */

var LanguageButton = cleverapps.UI.Button.extend({
    ctor: function (language, callback) {
        this.language = language;
        this.callback = callback;

        var styles = cleverapps.styles.LanguageButton;

        var padding = cc.padding(cleverapps.styles.UI.Button.SKIP_RESOLUTION);
        padding.left = 0;
        padding.right = 0;

        this._super({
            type: {
                button_png: bundles.buttons.frames.button_png,
                button_on_png: bundles.buttons.frames.button_on_png,
                button_off_png: bundles.buttons.frames.blue_button_png,
                SKIP_RESOLUTION: {
                    padding: padding
                }
            },
            content: this.createContent(),
            contentOff: this.createContent(true),
            width: styles.width,
            height: styles.height,
            onClicked: this.onClicked.bind(this)
        });

        if (this.isCurrentLanguage()) {
            this.disable();
        }
    },

    onClicked: function () {
        this.callback(this.language);

        this.disable();
    },

    isCurrentLanguage: function () {
        return cleverapps.settings.language === this.language;
    },

    updateEnabled: function () {
        if (this.isCurrentLanguage()) {
            this.disable();
        } else {
            this.enable();
        }
    },

    createContent: function (withCheckmark) {
        var styles = cleverapps.styles.LanguageButton;

        var icon = new cc.Sprite(bundles.flag_icons.frames[this.language + "_flag_icon"]);

        var text = cleverapps.UI.generateOnlyText(this.language, cleverapps.styles.FONTS.BUTTON_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setFontSize(styles.fontSize[this.language] || styles.fontSize.default);

        var textWrapper = cleverapps.UI.wrapWithPadding(text);
        textWrapper.width = styles.text.width;
        text.x = textWrapper.width / 2;
        text.y += styles.text.dy[this.language] || 0;

        var checkmark = withCheckmark ? new cc.Sprite(bundles.checkbox.frames.settings_checkmark_png) : new cc.Node();

        var checkmarkWrapper = cleverapps.UI.wrapWithPadding(checkmark);
        checkmarkWrapper.width = icon.width;
        checkmark.x = checkmarkWrapper.width / 2;

        return new cleverapps.Layout([icon, textWrapper, checkmarkWrapper], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
    }
});

cleverapps.styles.LanguageButton = {
    width: 420,
    height: 100,

    margin: 10,

    fontSize: {
        default: 35,
        ar: 60
    },

    text: {
        width: 240,
        dy: {
            ar: 18
        }
    }
};