/**
 * Created by andrey on 19.05.17.
 */

cleverapps.PaymentsHistory = function () {
    this.ids = [];
    this.time = [];
    this.price = [];

    this.load();

    this.onAddPaymentListeners = {};
};

cleverapps.PaymentsHistory.prototype.trim = function () {
    if (this.ids.length > cleverapps.PaymentsHistory.STORAGE_LIMIT) {
        this.ids = this.ids.slice(-cleverapps.PaymentsHistory.STORAGE_LIMIT);
    }
    if (this.time.length > this.ids.length) {
        this.time = this.time.slice(-this.ids.length);
    }
    if (this.price.length > this.ids.length) {
        this.price = this.price.slice(-this.ids.length);
    }
};

cleverapps.PaymentsHistory.prototype.addPayment = function (product) {
    this.ids.push(product.itemId);
    this.time.push(Date.now());
    this.price.push(product.priceUSD);

    this.save();

    this.runPaymentsListeners();
};

cleverapps.PaymentsHistory.prototype.runPaymentsListeners = function () {
    Object.values(this.onAddPaymentListeners).forEach(cleverapps.callFunc);
};

cleverapps.PaymentsHistory.prototype.isPayer = function () {
    return this.ids.length > 0;
};

cleverapps.PaymentsHistory.prototype.getLastPaymentTime = function () {
    return this.time.length > 0 ? this.time[this.time.length - 1] : undefined;
};

cleverapps.PaymentsHistory.prototype.load = function (stored) {
    var save = stored || cleverapps.dataLoader.load(DataLoader.TYPES.PAYMENTS_HISTORY);

    if (save && save.payments || Array.isArray(save)) {
        this.migrateOldFormat(save);
        this.migratePrice();
        this.trim();
        this.save();
        return;
    }

    if (save && save.ids) {
        this.ids = save.ids;
        this.time = save.time.map(cleverapps.expandTime);

        if (save.price) {
            this.price = save.price.map(function (price) {
                return price / 100;
            });
        } else {
            this.migratePrice();
        }

        this.trim();
    }
};

cleverapps.PaymentsHistory.prototype.migrateOldFormat = function (save) {
    var payments = Array.isArray(save) ? save : save.payments;

    var toProductId = function (payment) {
        var id = payment && payment.productId && payment.productId.facebook;
        id = id && id.substring(id.lastIndexOf("/") + 1);

        var product = connector.payments.getProduct(id);
        if (product) {
            return product.itemId;
        }

        return id;
    };

    payments = payments.filter(toProductId);

    this.time = payments.map(function (payment) {
        return payment.time;
    });

    this.ids = payments.map(toProductId);
};

cleverapps.PaymentsHistory.prototype.migratePrice = function () {
    this.price = this.ids.map(function (id) {
        var product = connector.payments.getProduct(id);
        if (product) {
            return product.priceUSD;
        }

        return 0;
    });
};

cleverapps.PaymentsHistory.prototype.getInfo = function () {
    return {
        time: this.time.map(cleverapps.compactTime),
        ids: this.ids,
        price: this.price.map(function (price) {
            return Math.floor(price * 100);
        })
    };
};

cleverapps.PaymentsHistory.prototype.updateInfo = function (serverData) {
    if (!serverData) {
        return;
    }

    this.load(serverData);
    this.save(true);

    this.runPaymentsListeners();
};

cleverapps.PaymentsHistory.prototype.save = function (fromServer) {
    this.trim();

    cleverapps.dataLoader.save(DataLoader.TYPES.PAYMENTS_HISTORY, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("paymentshistory");
    }
};

cleverapps.PaymentsHistory.prototype.getVipEnd = function (threshold) {
    var MONTH = cleverapps.parseInterval("1 month");

    var MONTH_AGO = Date.now() - MONTH;

    var i = this.ids.length - 1;
    var j = this.ids.length - 1;

    var lastPeriodTotal = 0;

    for (; i >= 0 && this.time[i] + MONTH >= MONTH_AGO; i--) {
        lastPeriodTotal += this.price[i];

        while (this.time[i] + MONTH < this.time[j]) {
            lastPeriodTotal -= this.price[j];
            j--;
        }

        if (this.time[j] < MONTH_AGO) {
            break;
        }

        if (lastPeriodTotal >= threshold) {
            return this.time[j] + MONTH;
        }
    }
};

cleverapps.PaymentsHistory.BRACKET_UNDECIDED = -1;
cleverapps.PaymentsHistory.BRACKET_NONE = 0;
cleverapps.PaymentsHistory.BRACKET_WEAK = 1;
cleverapps.PaymentsHistory.BRACKET_MEDIUM = 2;
cleverapps.PaymentsHistory.BRACKET_WHALE = 3;
cleverapps.PaymentsHistory.BRACKET_BIGWHALE = 4;

cleverapps.PaymentsHistory.BRACKETS = [2.99, 9.99, 29.99, 99.99];

cleverapps.PaymentsHistory.STORAGE_LIMIT = 25;

cleverapps.PaymentsHistory.UNDECIDED_LEVEL_THRESHOLD = 6.5;

cleverapps.PaymentsHistory.initialize = function () {
    if (connector.platform.oneOf(connector.WECHAT)) {
        cleverapps.PaymentsHistory.UNDECIDED_LEVEL_THRESHOLD = 0;
    }
};

cleverapps.PaymentsHistory.prototype.classify = function () {
    return cleverapps.PaymentsHistory.classify({
        ids: this.ids,
        price: this.price,
        time: this.time,
        subscription: cleverapps.subscription && cleverapps.subscription.isActive(),
        level: cleverapps.user.getFloatLevel(),
        highMonetization: cleverapps.flags.highMonetization && cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR
    });
};

cleverapps.PaymentsHistory.prototype.getFavoriteProduct = function (context, filter) {
    var idsCounter = {};
    var latestPurchaseTime = {};

    this.ids.filter(function (id) {
        return filter.includes(id) && connector.payments.getProduct(id);
    }).forEach(function (id, index) {
        idsCounter[id] = (idsCounter[id] || 0) + 1;
        latestPurchaseTime[id] = this.time[index];
    }, this);

    var productId = Object.keys(idsCounter).reduce(function (a, b) {
        if (idsCounter[a] > idsCounter[b]) {
            return a;
        }
        if (idsCounter[a] < idsCounter[b]) {
            return b;
        }
        return latestPurchaseTime[a] > latestPurchaseTime[b] ? a : b;
    }, null);

    if (!productId) {
        productId = filter[0];
    }

    return Product.CreateById(productId, context);
};

cleverapps.PaymentsHistory.prototype.checkAvailable = function (available) {
    available = available || {};

    if (available.forPayer && !this.isPayer()) {
        return false;
    }
    return true;
};

cleverapps.PaymentsHistory.classify = function (options) {
    var lastPeriodTotal = 0;
    var MONTH_AGO = Date.now() - cleverapps.parseInterval("1 month");
    for (var i = options.ids.length - 1; i >= 0; i--) {
        if (options.time[i] < MONTH_AGO) {
            break;
        }

        lastPeriodTotal += options.price[i];
    }

    if (options.subscription) {
        lastPeriodTotal += 9.99;
    }

    var bracket = 0;
    for (; bracket < cleverapps.PaymentsHistory.BRACKETS.length; bracket++) {
        if (lastPeriodTotal < cleverapps.PaymentsHistory.BRACKETS[bracket] + 0.001) {
            break;
        }
    }

    if (bracket === cleverapps.PaymentsHistory.BRACKET_NONE
        && options.level < cleverapps.PaymentsHistory.UNDECIDED_LEVEL_THRESHOLD) {
        if (connector.platform.oneOf(connector.YANDEX)) {
            var payingStatus = connector.social.getPlugin().payingStatus;
            if (payingStatus === "paying") {
                return cleverapps.PaymentsHistory.BRACKET_WHALE;
            }
            if (payingStatus === "partially_paying") {
                return cleverapps.PaymentsHistory.BRACKET_WEAK;
            }
            if (payingStatus === "not_paying") {
                return cleverapps.PaymentsHistory.BRACKET_NONE;
            }
        }
        if (options.highMonetization) {
            return cleverapps.PaymentsHistory.BRACKET_UNDECIDED;
        }
    }

    return bracket;
};

if (typeof cc === "undefined") {
    module.exports = cleverapps.PaymentsHistory;
}
